import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomeOne from "./Components/Home/HomeOne";
import ErrorPage from "./Components/Pages/404Page";
import AboutTwo from "./Components/Pages/AboutTwo";
import ContactUs from "./Components/Pages/ContactUs";
import Courses from "./Components/Pages/Courses";

function App() {
  return (
    <BrowserRouter>
      <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/about" element={<AboutTwo />} />
          <Route path="courses" element={<Courses />} />
          <Route path="/contacts" element={<ContactUs />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
