/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tab } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { courseData } from "../constant/dummyData";
import {
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  clock,
  file,
  review,
  star,
} from "../constant/images";

const FilteredCourse = ({ classNameForTabOne, classNameForTabTwo }) => {
  return (
    <Tab.Group>
      <div className="flex flex-wrap gap-5 justify-center  items-center mb-14">
        <div className="flex-1 flex flex-wrap gap-5  space-x-6  items-center">
          <Tab.List
            as="ul"
            id="tabs-nav"
            className=" flex space-x-4 cata-Tbas"
          ></Tab.List>
        </div>
      </div>
      <Tab.Panels as="div" id="tabs-content">
        <Tab.Panel as="div" id="tab1" className="tab-content">
          <div className={classNameForTabOne}>
            {courseData.map((item, index) => (
              <Link
                className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
                to={"#"}
                key={item.id * index}
              >
                {/* <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
                  <img
                    src={item.img}
                    alt=""
                    className=" w-full h-full object-cover rounded-t-[8px]"
                  />
                </div> */}
                <div className="course-content p-8">
                  <div className="text-secondary font-bold text-2xl mb-3">
                    {item.price}
                  </div>
                  <h4 className=" text-xl mb-3 font-bold">{item.title}</h4>
                  <div className="flex justify-between  flex-wrap space-y-1 xl:space-y-0">
                    <span className=" flex items-center space-x-2 mr-3">
                      <img src={file} alt="" />
                      <span>2 Lessons</span>
                    </span>
                    <span className=" flex items-center space-x-2 mr-3">
                      <img src={clock} alt="" />
                      <span> {item.time} </span>
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default FilteredCourse;
