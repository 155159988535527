import React from "react";
import { about5, h, videoIcon, whiteWebIcon } from "../../constant/images";

const About = () => {
  return (
    <div className="about-area  section-padding-top pb-16 relative z-[1]">
      <div className=" absolute right-[7%] top-[20%] z-[-1] hidden xl:block">
        <img src={h} alt="" />
      </div>
      <div className="container">
        <div className="grid grid-cols-12 xl:gap-[70px] lg:gap-10 gap-6">
          <div className="xl:col-span-7 lg:col-span-6 col-span-12">
            <img src={about5} alt="" />
          </div>
          <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
            <div className="mini-title">About IPSEC</div>
            <h4 className="column-title ">
              Unlock Your Future with Industry-Leading{" "}
              <span className="shape-bg"> Training</span>
            </h4>
            <div>
              At IPSEC, we offer a comprehensive range of courses designed to
              equip you with the practical knowledge and certifications needed
              to thrive in today’s fast-evolving tech landscape. Our expert-led
              courses are tailored to meet industry standards, ensuring that you
              stand out in the competitive job market.
            </div>
            <ul className=" list-item space-y-6 pt-8">
              <li className="flex">
                <div className="flex-none mr-6">
                  <div className="h-20 w-20 rounded-full bg-white  shadow-box10 flex flex-col justify-center items-center">
                    <img src={videoIcon} alt="" className="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-xl mb-1">Real-time Interaction</h4>
                  <div>
                    Collaborate with instructors and peers, ask questions, and
                    get immediate feedback during classes.
                  </div>
                </div>
              </li>
              <li className="flex">
                <div className="flex-none mr-6">
                  <div className="h-20 w-20 rounded-full bg-white  shadow-box10 flex flex-col justify-center items-center">
                    <img src={whiteWebIcon} alt="" className=" " />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-xl mb-1">State-of-the-Art Facilities</h4>
                  <div>
                    Learn in modern classrooms equipped with the latest
                    technology and tools to simulate real-world scenarios.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
