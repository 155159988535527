import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import About from "../HomeThreeComponents/About";
import CourseBlock from "../HomeThreeComponents/CourseBlock";
import PageBanner from "../PageBanner";

const AboutTwo = () => {
  return (
    <>
      <Header />
      <PageBanner title={"About Us"} pageTitle="About Us" />
      <About />
      <CourseBlock />
      <Footer />
    </>
  );
};

export default AboutTwo;
