import {
  blog1,
  blog2,
  blog3,
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  counter1,
  counter2,
  counter3,
  counter4,
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  t7,
  t8,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
} from "./images";

export const achivementCounter = [
  {
    icon: counter1,
    num: 82 + "k",
    sector: "Enrolled Students",
  },
  {
    icon: counter2,
    num: 460,
    sector: "Academic Programs",
  },
  {
    icon: counter3,
    num: 20,
    sector: "Certified Studens",
  },
  {
    icon: counter4,
    num: 200,
    sector: "Award Winnig",
  },
];

export const blogArticleData = [
  {
    title: "Fashion and Luxury Fashion in a Changing",
    img: c1,
  },
  {
    title: "Creative Writing Through Storytelling",
    img: c2,
  },
  {
    title: "Product Manager Learn The Skills & Job",
    img: c3,
  },
  {
    title: "The Power of Podcast for Storytelling",
    img: c4,
  },
];

export const courseData = [
  {
    id: 0,
    title: "CCNA (Cisco Certified Network Associate)",
    img: c1,
    time: "3 Months",
    lesson: " Associate",
  },
  {
    id: 1,
    title: "CCNP (Cisco Certified Network Professional)",
    img: c2,
    time: "3 Months",
    lesson: "Professional",
  },
  {
    id: 3,
    title: "CCIE (Cisco Certified Internetwork Expert)",
    img: c3,
    time: "3 Months",
    lesson: "Expert",
  },
  {
    id: 4,
    title: "MCSE (Microsoft Certified Solutions Expert)",
    img: c4,
    time: "3 Months",
    lesson: "Expert",
  },

  {
    id: 5,
    title: "HCIA (Huawei Certified ICT Associate)",
    img: c6,
    time: "3 Months",
    lesson: " Associate",
  },
  {
    id: 6,
    title: "HCIP (Huawei Certified ICT Professional)",
    img: c6,
    time: "3 Months",
    lesson: "Professional",
  },
  {
    id: 7,
    title: "Web Development",
    img: c6,
    time: "3 Months",
    lesson: "Professional",
  },
  {
    id: 8,
    title: "Python",
    img: c6,
    time: "3 Months",
    lesson: "Associate",
  },
  {
    id: 9,
    title: "MERN Stcak",
    img: c6,
    time: "3 Months",
    lesson: "Professional",
  },
  {
    id: 10,
    title: "Graphics Designing",
    img: c6,
    time: "3 Months",
    lesson: "Professional",
  },
  {
    id: 11,
    title: "UI / UX Designing",
    img: c6,
    time: "3 Months",
    lesson: "Professional",
  },
];

export const topicsData = [
  { logo: t1, title: "Data Science", quantity: "68 Courses" },
  { logo: t2, title: "UI/UX Design", quantity: "UI/UX Design" },
  { logo: t3, title: "Modern Physics", quantity: "68 Courses" },
  { logo: t4, title: "Music Production", quantity: "45 Courses" },
  { logo: t5, title: "Data Science", quantity: "68 Courses" },
  { logo: t6, title: "Finances", quantity: "68 Courses" },
  { logo: t7, title: "Global Science", quantity: "75 Courses" },
  { logo: t8, title: "Game Design", quantity: "12 Courses" },
];

export const teamData = [
  { img: team1, name: "Erics Widget", title: "UI/UX Designer" },
  { img: team2, name: "Daniel Steven", title: "Developer" },
  { img: team3, name: "Nelson Decosta", title: "Digital Marketer" },
  { img: team4, name: "Selina Gomez", title: "UI/UX Designer" },
];
export const instructorData = [
  { img: team5, name: "Erics Widget", title: "UI/UX Designer" },
  { img: team6, name: "Daniel Steven", title: "Developer" },
  { img: team7, name: "Nelson Decosta", title: "Digital Marketer" },
  { img: team8, name: "Selina Gomez", title: "UI/UX Designer" },
];

export const blogsData = [
  {
    img: blog1,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Professional Mobile Painting and Sculpting",
  },
  {
    img: blog2,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Professional Ceramic Moulding for Beginners",
  },
  {
    img: blog3,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Education Is About Create Leaders For Tomorrow",
  },
];
